import React from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
function ViewDoc() {
    const location = useLocation();
    console.log(location)
    const renderField = (field, index) => {


        if (field.toolType === 'WEB_LINK') {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <a href={field.title} >{field.title}</a>
              </div>
            </div>
          )
    
    
        } else if (field.toolType === 'IMAGE') {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <img src={'https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/form_image/' + field.title} style={{ maxWidth: '100%' }} />
              </div>
            </div>
          )
        } else if (field.toolType === 'YOUTUBE') {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <iframe sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation" src={field.title} allowfullscreen=""></iframe>
              </div>
            </div>
          )
        } else if (field.toolType === 'VIDEO') {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
    
                <video controls style={{ maxWidth: '100%' }}>
                  <source src={'https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/form_video/' + field.title} type="video/mp4" />
                  Your browser does not support video play.
                </video>
              </div>
            </div>
          )
        } else if (field.toolType === "PARAGRAPH") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p dangerouslySetInnerHTML={{ __html: field.content }} />
    
              </div>
            </div>
          )
        } else if (field.toolType === "AUDIO") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <div className="col-12 text-center">
                  <audio controls style={{ maxWidth: '100%' }}>
                    <source src={'https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/form_audio/' + field.title} />
                  </audio>
                </div>
    
              </div>
            </div>
          )
        } else if (field.toolType === "PDF") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <div className="col-12 text-center">
                  <a href={'https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/pdf_uploads/' + field.title} target="_blank"><i className="fa fa-file-pdf-o fa-5x"></i><p style={{ textDecoration: 'none' }}>click to view</p></a></div>
    
              </div>
            </div>
          )
        } else if (field.toolType === "EMBEDCODE") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <div className="col-12 text-center">
                  <div dangerouslySetInnerHTML={{ __html: field.title }} style={{ width: '100%' }} /></div>
    
              </div>
            </div>
          )
        }
        else if (field.toolType === "MCQ") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p
                  dangerouslySetInnerHTML={{ __html: field.title }}
                />
                {field.radios.map(item => {
                  return (
                    <div className="form-check">
                      <input
    
    
                        className="form-check-input"
                        type="checkbox"
                        id="isRequired"
                      />
                      <label className="form-check-label" htmlFor="isRequired">
                        {item.value}
                      </label>
                    </div>
                  )
                })}
    
              </div>
            </div>
          )
        } else if (field.toolType === "TEXT_INPUT") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p>{field.title}</p>
                <input
    
    
                  className="form-control"
                  type="text"
                  id="isRequired"
                />
              </div>
            </div>
          )
        } else if (field.toolType === "IMAGE_INPUT") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p>{field.title}</p>
    
              </div>
            </div>
          )
        } else if (field.toolType === "VIDEO_INPUT") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p>{field.title}</p>
    
              </div>
            </div>
          )
        } else if (field.toolType === "AUDIO_INPUT") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p>{field.title}</p>
    
              </div>
            </div>
          )
        } else if (field.toolType === "OPTION_INPUT") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p
                  dangerouslySetInnerHTML={{ __html: field.title }}
                />
                {field.radios.map(item => {
                  return (
                    <div className="form-check">
                      <input
    
    
                        className="form-check-input"
                        type="checkbox"
                        id="isRequired"
                      />
                      <label className="form-check-label" htmlFor="isRequired">
                        {item.value}
                      </label>
                    </div>
                  )
                })}
    
              </div>
            </div>
          )
        } else if (field.toolType === "SIGN_INPUT") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p>{field.title}</p>
    
              </div>
            </div>
          )
        } else if (field.toolType === "CHECK_INPUT") {
          return (
            <div className='card mb-3'>
              <div className='card-body boxShadow'>
                <p
                  dangerouslySetInnerHTML={{ __html: field.title }}
                />
                {field.radios.map(item => {
                  return (
                    <div className="form-check">
                      <input
    
    
                        className="form-check-input"
                        type="checkbox"
                        id="isRequired"
                      />
                      <label className="form-check-label" htmlFor="isRequired">
                        {item.value}
                      </label>
                    </div>
                  )
                })}
    
              </div>
            </div>
          )
        } else if (field.toolType === "MULTIMEDIA") {
          return (
            <></>
          )
        }
      }
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">{location.state.state.name}</h4>
                                <div className="row">
                                  
                                        <div className='col-9'>
                                            <div className={`animate-col-md-12`}>
                                                {location.state.state !== '' ?
                                                   location.state.state.value ?
                                                        JSON.parse(location.state.state.value).map((field, index) => {
                                                            return renderField(field, index)
                                                        })
                                                        : '' : ''
                                                }
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <h5>Author</h5>
                                            <p>{location.state.state.creator.firstname} {location.state.state.creator.lastname}</p>
                                            <h5>Reviewer</h5>
                                            <p>{location.state.state.reviewer.firstname} {location.state.state.creator.lastname}</p>
                                            <h5>Approver</h5>
                                            <p>{location.state.state.approver.firstname} {location.state.state.creator.lastname}</p>

                                            <h4>Release Date</h4>
                                            <p>{location.state.state.createdAt}</p>



                                        </div>
                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewDoc